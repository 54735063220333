<template>
    <tablebox
        v-loading="loading"
        :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner"
        :element-loading-background="config.loading.background"
        class="dp-f"
    >
        <choose class="mr-20" @Emit="butemit" :Data="[{title:'发票管理'},{title:'开票历史'}]"></choose>
        <management v-if="state.butIndex==0"></management>
        <history v-if="state.butIndex==1"></history>
    </tablebox>
</template>
<script setup>
import { reactive,ref,unref } from 'vue'
import choose from "@/components/sidebar/choose.vue"
import management from "./components/invoice/management.vue"//账户管理
import history from "./components/invoice/history.vue"//充值记录
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const state = reactive({
  butIndex:0,//按钮选中下标
})
// 按钮返回
const butemit=((el)=>{
    state.butIndex=el
    // console.log('按钮返回',el)
})
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss";  //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

</style>